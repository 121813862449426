<template>
  <p class="font-bold text-xl mb-4">
    {{ t('tutorials.extranet.institution_employee.invite.title') }}
  </p>

  <!-- eslint-disable vue/no-v-html -->
  <p
    v-html="t('tutorials.extranet.institution_employee.invite.content.button')"
  />

  <p class="mt-4">
    {{ t('tutorials.extranet.institution_employee.invite.content.form') }}
  </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
