<template>
  <app-icon
    v-if="step.icon"
    v-bind="step.icon"
    size="text-xl"
    :classes="style"
  />
</template>

<script setup>
import { computed } from 'vue'

import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

// ---------- STYLE ----------

const style = computed(() => {
  if (props.currentStep === props.step.step) return ['text-white']
  if (props.currentStep > props.step.step) return ['text-theme-600']

  return ['text-gray-300']
})
</script>
