<template>
  <div class="py-5 mb-2">
    <div class="p-4">
      <div class="flex items-center">
        <template
          v-for="step in steps"
          :key="step.step"
        >
          <div class="flex items-center text-theme-600 relative">
            <app-form-wizard-nav-chip
              :step="step"
              :current-step="currentStep"
            />

            <div class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-theme-600">
              {{ step.name }}
            </div>
          </div>

          <app-form-wizard-nav-separator
            :step="step"
            :current-step="currentStep"
            :total-steps="totalSteps"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import useFormWizard from '@extranet/hooks/formWizard'
import AppFormWizardNavChip from '@extranet/components/ui/form_wizard/AppFormWizardNavChip.vue'
import AppFormWizardNavSeparator from '@extranet/components/ui/form_wizard/AppFormWizardNavSeparator.vue'

const props = defineProps({
  // Array of step objects
  steps: {
    type: Array,
    default: () => [],
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

// ---------- STEPS ----------

const { totalSteps } = useFormWizard(props)
</script>
