import {
  computed,
  ref,
  inject,
  toRefs,
  watch,
} from 'vue'

export default function useFormWizard(props) {
  /**
   * Form used in FormWizard
   */
  const form = inject('form')

  /**
   * Current form wizard step
   */
  const currentStep = ref(0)

  /**
   * Total steps of the form wizard
   */
  const totalSteps = computed(() => (
    (props.steps.length - 1)
      ?? 0
  ))

  /**
   * Convert props to ref
   * (to make watch working)
   */
  const { reset } = toRefs(props)

  /**
   * Proceed to next form wizard step
   */
  async function nextStep() {
    await form.value.validate()

    if (Object.keys(form.value.errors).length === 0) {
      if (currentStep.value < totalSteps.value) {
        currentStep.value += 1
      }
    }
  }

  /**
   * Proceed to previous form wizard step
   */
  function previousStep() {
    if (currentStep.value > 0) {
      currentStep.value -= 1
    }
  }

  /**
   * Reset wizard to first step
   */
  function resetWizard() {
    currentStep.value = 0
  }

  /**
   * Watch asking for wizard reset
   */
  if (reset.value) {
    watch(reset, (newValue) => {
      if (newValue) {
        resetWizard()
      }
    })
  }

  return {
    form,
    currentStep,
    totalSteps,
    nextStep,
    previousStep,
    resetWizard,
  }
}
