<template>
  <div :class="style">
    <app-form-wizard-nav-icon
      :step="step"
      :current-step="currentStep"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppFormWizardNavIcon from '@extranet/components/ui/form_wizard/AppFormWizardNavIcon.vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

// ---------- STYLE ----------

const style = computed(() => {
  const classes = [
    'rounded-full',
    'transition',
    'duration-500',
    'ease-in-out',
    'h-12',
    'w-12',
    'py-3',
    'border-2',
    'text-center',
  ]

  switch (true) {
    case props.currentStep === props.step.step:
      classes.push('bg-theme-600 border-theme-600')
      break
    case props.currentStep > props.step.step:
      classes.push('border-theme-600')
      break
    default:
      classes.push('border-gray-300')
  }

  return classes.join(' ')
})
</script>
