<template>
  <div
    v-show="showStep"
    class="p-4"
  >
    <component
      :is="step.component"
      v-if="step.component"
      v-bind="componentPropsToUse"
      v-on="componentEvents"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

const showStep = computed(() => (
  props.currentStep === props.step?.step
))

// Compute props passed to the form-control component
const componentPropsToUse = computed(() => {
  const finalPropsToUse = {}
  const propsAllowed = Object.keys(props)
  const computedProps = {
    'step': props.step,
    'currentStep': props.currentStep,
  }

  // Merge computed props and forced props
  const allProps = {
    ...computedProps,
    ...props.formControlProps, // Prioritize forced props
  }

  Object.entries(allProps).forEach(([key, value]) => {
    // Only use props register by the component
    if (propsAllowed.includes(key)) {
      finalPropsToUse[key] = value
    }
  })

  return finalPropsToUse
})

// Compute events passed to the form-control events
const componentEvents = computed(() => {
  const baseEventsToUse = {}
  const finalEventsToUse = {}
  const eventsAllowed = props.step.emits

  Object.entries(baseEventsToUse).forEach(([key, value]) => {
    // Only use events emitted by the component
    if (eventsAllowed.includes(key)) {
      finalEventsToUse[key] = value
    }
  })

  return finalEventsToUse
})
</script>
