<template>
  <template v-if="isCurrentStep">
    <form-group
      rules="required"
      name="point_of_interest_id"
      type="select"
      :label="t('validation.attributes.institution')"
      :form-control-props="{
        filterable: false,
        options: handlePois,
      }"
    >
      <template #control-vue-multiselect-option="option">
        <div>
          <strong>
            {{ option.label }}
          </strong>
          <br>
          <em class="text-sm">
            {{ option.subLabel }}
          </em>
        </div>
      </template>
    </form-group>

    <div v-if="isExisting">
      <form-group
        name="user_id"
        rules="required"
        type="select"
        :form-control-props="{
          options: handleUsersSearch,
          filterable: false,
        }"
        :hint="t('form.hints.point_of_interest.cant_have_employees')"
      >
        <template #control-vue-multiselect-option="option">
          <div>
            <strong>
              {{ option.label }}
            </strong>

            <em class="text-sm block">
              <span v-if="option.nickname">
                {{ `${capitalize(t('validation.attributes.nickname'))} :` }}
                {{ option.nickname }}
              </span>

              <span v-if="option.nickname && option.fullName">
                &#183;
              </span>

              <span v-if="option.fullName">
                {{ `${capitalize(t('validation.attributes.name'))} :` }}
                {{ option.fullName }}
              </span>
            </em>
          </div>
        </template>
      </form-group>
    </div>

    <div v-else>
      <div class="flex flex-col md:flex-row">
        <form-group
          rules="required"
          name="last_name"
          type="text"
          class="flex-1 mr-0 md:mr-2"
        />

        <form-group
          rules="required"
          name="first_name"
          type="text"
          class="flex-1"
        />
      </div>

      <form-group
        rules="required|email"
        name="email"
        type="email"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />
    </div>
  </template>

  <template v-else>
    <form-group
      type="hidden"
      name="point_of_interest_id"
    />

    <form-group
      type="hidden"
      name="user_id"
    />

    <form-group
      type="hidden"
      name="email"
    />

    <form-group
      type="hidden"
      name="last_name"
    />

    <form-group
      type="hidden"
      name="first_name"
    />
  </template>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { compact, uniqBy, capitalize } from 'lodash'

import {
  fetchOwnedPointsOfInterest,
  fetchButlers,
  fetchTouristOfficeEmployees,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import useGeoloc from '@shared/hooks/geoloc'
import useAccess from '@extranet/hooks/access'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

const { t } = useI18n()

// ---------- CURRENT STEP ----------

const isCurrentStep = computed(() => (
  props.step.step === props.currentStep
))

// ---------- FORM ----------

const form = inject('form')

const existingValue = computed(() => (
  form.value.values.existing_user
))

const isExisting = computed(() => (
  existingValue.value === 'true'
))

// ---------- POINTS OF INTEREST ----------

const { inlineAddress } = useGeoloc()
const { getAttributeTranslation } = useLocale()

// Return points of interest options used in select control,
// based on points of interest resources
function formatPoisOptions(poisResources) {
  return poisResources.map((poiResource) => {
    const subLabel = inlineAddress(poiResource?.relationships?.address)

    return {
      label: getAttributeTranslation(poiResource.attributes.title),
      subLabel,
      value: poiResource.id,
    }
  })
}

function handlePois() {
  return new Promise((resolve) => {
    let options = []

    const params = {
      has_employees: true,
    }

    fetchOwnedPointsOfInterest(params)
      .then((response) => {
        const apiOptions = formatPoisOptions(response.data.data)
        options = compact(uniqBy(options.concat(apiOptions), 'value'))

        // Default POI value if user has only one claim
        if (options.length === 1) {
          form.value.setFieldValue('point_of_interest_id', options[0].value)
        }
      })
      .finally(() => {
        resolve(options)
      })
  })
}

// ---------- USERS ----------

// Return users options used in select control,
// based on users resources
function formatUsersOptions(users) {
  return users.map((userResource) => ({
    label: userResource.attributes.public_id,
    nickname: userResource?.attributes?.name,
    fullName: userResource?.attributes?.anonymized_fullname,
    value: userResource.id,
  }))
}

function handleUsersSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    const params = {
      'source': 'extranet',
      'search': searchKeywords,
      'exclude_user_employees': true,
    }

    if (employeeRole.value === 'butler') {
      fetchButlers(params)
        .then((e) => {
          // Get the new options from the response
          options = uniqBy(options.concat(formatUsersOptions(e.data.data)), 'value')
        })
        .finally(() => {
          resolve(options)
        })
    }

    if (employeeRole.value === 'touristOfficeEmployee') {
      fetchTouristOfficeEmployees(params)
        .then((e) => {
          // Get the new options from the response
          options = uniqBy(options.concat(formatUsersOptions(e.data.data)), 'value')
        })
        .finally(() => {
          resolve(options)
        })
    }
  })
}

// ---------- ROLES ----------

const {
  dashboardHotelierAccess,
  dashboardTouristOfficeAccess,
} = useAccess()

const employeeRole = computed(() => {
  let role = null

  if (dashboardHotelierAccess.value) {
    role = 'butler'
  }

  if (dashboardTouristOfficeAccess.value) {
    role = 'touristOfficeEmployee'
  }

  return role
})
</script>
