<template>
  <!-- Breadcrumbs -->
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Title -->
  <teleport-wrapper to="#mainTitle">
    {{ t('institutions_employees.index.my_employees') }}
  </teleport-wrapper>

  <!-- Help -->
  <teleport-wrapper to="#help">
    <tutorial-institution-employee-index />
  </teleport-wrapper>

  <div
    class="mb-5 flex justify-end items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full"
  >
    <!-- Create button -->
    <app-button
      v-if="store.state.auth.user.authorizations.institutions_employees.create"
      feature="create"
      :label="getLabel()"
      class="w-full md:w-fit"
      @click="router.push({ name: 'invite-institutions-employees' })"
    />
  </div>

  <!-- Results -->
  <institutions-employees-table
    mode="owner"
  />
</template>

<script setup>
import {
  computed,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useInstitutionEmployee from '@extranet/hooks/institutionEmployee'
import breadcrumbInstitutionEmployee from '@extranet/breadcrumbs/institution_employee'
import InstitutionsEmployeesTable from '@extranet/components/institutions_employees/InstitutionsEmployeesTable.vue'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import TutorialInstitutionEmployeeIndex from '@extranet/components/tutorials/institution_employee/TutorialInstitutionEmployeeIndex.vue'

const router = useRouter()
const store = useStore()
const { t } = useI18n()

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbInstitutionEmployee()

const breadcrumbs = computed(() => (
  index()
))

// ---------- LABELS ----------

const { getLabel } = useInstitutionEmployee()
</script>
