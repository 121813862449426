<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <app-form-wizard
      :steps="steps"
    />
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { shallowRef } from 'vue'

import useForm from '@shared/hooks/form/form'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import AppFormWizard from '@extranet/components/ui/form_wizard/AppFormWizard.vue'
import StepType from '@extranet/components/resources/employee/form/StepType.vue'
import StepForm from '@extranet/components/resources/employee/form/StepForm.vue'

const props = defineProps({
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

// ---------- FORM ----------

const { handleSubmit } = useForm(props, { emits })

// ---------- FORM WIZARD ----------

const { t } = useI18n()

const steps = shallowRef([
  {
    step: 0,
    name: t('institutions_employees.invitation_wizard.steps.account'),
    component: StepType,
    props: {},
    emits: {},
    icon: {
      library: 'coolicon',
      name: 'user',
      iconStyle: null,
    },
  },
  {
    step: 1,
    name: t('institutions_employees.invitation_wizard.steps.invitation'),
    component: StepForm,
    props: {},
    emits: {},
    icon: {
      library: 'coolicon',
      name: 'mail',
      iconStyle: null,
    },
  },
])
</script>
