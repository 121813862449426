<template>
  <div
    v-if="step.step !== totalSteps"
    :class="style"
  />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Step object which contains the following struct:
  //   step: 0,                       // step counter
  //   name: 'Name',                  // step name
  //   component: StepDocuments,      // component to display
  //   props: {},                     // component's props
  //   emits: {},                     // component's events
  //   icon: {                        // icon to display in form wizard nav
  //     library: 'coolicon',         // icon's library used
  //     name: 'file_blank_outline',  // icon's name
  //     iconStyle: null,             // icon's style
  //   },
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
  // Total steps quantity
  totalSteps: {
    type: Number,
    default: 0,
  },
})

// ---------- STYLE ----------

const style = computed(() => {
  const classes = [
    'flex-auto',
    'border-t-2',
    'transition',
    'duration-500',
    'ease-in-out',
  ]

  if (props.currentStep > props.step.step) {
    classes.push('border-theme-600')
  } else {
    classes.push('border-gray-300')
  }

  return classes.join(' ')
})
</script>
