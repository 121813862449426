import { useI18n } from 'vue-i18n'
import { isNil } from 'lodash'

import useAccess from '@extranet/hooks/access'

export default function useInstitutionEmployee() {
  const { t } = useI18n()
  const {
    dashboardHotelierAccess,
    dashboardTouristOfficeAccess,
  } = useAccess()

  function getLabel() {
    let labelNew = null

    labelNew = dashboardHotelierAccess.value ? t('extranet.employees.actions.new-hotelier') : null

    if (isNil(labelNew) && dashboardHotelierAccess.value) {
      labelNew = t('extranet.employees.actions.new-hotelier')
    }

    if (isNil(labelNew) && dashboardTouristOfficeAccess.value) {
      labelNew = t('extranet.employees.actions.new-tourist-office')
    }

    if (isNil(labelNew)) {
      labelNew = t('extranet.employees.actions.new')
    }

    return labelNew
  }

  return {
    getLabel,
  }
}
