<template>
  <app-form-wizard-nav
    :steps="steps"
    :current-step="currentStep"
  />

  <app-form-wizard-step
    v-for="step in steps"
    :key="step.step"
    :step="step"
    :current-step="currentStep"
  />

  <div class="grid grid-cols-2 gap-4 my-4 px-2">
    <div class="text-left">
      <app-button
        v-if="currentStep !== 0"
        class="mx-2"
        emphasis="medium"
        :label="t('pagination.previous_alt')"
        @click="previousStep"
      />
    </div>

    <div class="text-right">
      <app-button
        v-if="currentStep < totalSteps"
        class="mx-2"
        emphasis="medium"
        :label="t('pagination.next_alt')"
        @click="nextStep"
      />

      <app-button
        v-if="currentStep === totalSteps"
        feature="confirm"
        type="submit"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useFormWizard from '@extranet/hooks/formWizard'
import AppFormWizardStep from '@extranet/components/ui/form_wizard/AppFormWizardStep.vue'
import AppFormWizardNav from '@extranet/components/ui/form_wizard/AppFormWizardNav.vue'
import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Array of step objects
  steps: {
    type: Array,
    default: () => [],
  },
  // Reset the wizard
  reset: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

// ---------- STEPS ----------

const {
  currentStep,
  totalSteps,
  previousStep,
  nextStep,
} = useFormWizard(props)
</script>
