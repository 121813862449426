<template>
  <!-- Breadcrumbs -->
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Title -->
  <teleport-wrapper to="#mainTitle">
    {{ getLabel() }}
  </teleport-wrapper>

  <app-card-item class="p-4 max-w-screen-md">
    <employee-invitation-wizard
      :additional-errors="formErrors"
      :submitting="formSubmitting"
      @submitted="handleSubmit"
    />
  </app-card-item>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { inviteEmployee } from '@shared/http/api'
import useInstitutionEmployee from '@extranet/hooks/institutionEmployee'
import breadcrumbInstitutionEmployee from '@extranet/breadcrumbs/institution_employee'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import EmployeeInvitationWizard from '@extranet/components/resources/employee/EmployeeInvitationWizard.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

// ---------- FORM SUBMIT ----------

const formSubmitting = ref(false)
const formErrors = ref({})

function handleSubmit(attributes) {
  if (!formSubmitting.value) {
    formSubmitting.value = true

    const params = {
      data: {
        type: 'users',
        attributes: { ...attributes },
      },
    }

    inviteEmployee(params)
      .then(() => {
        router.push({ name: 'index-institutions-employees' })
        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('invitations.success.professional.title'),
            type: 'success',
          },
        )
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- BREADCRUMBS ----------

const { invite } = breadcrumbInstitutionEmployee()

const breadcrumbs = computed(() => (
  invite()
))

// ---------- TITLE ----------

const { getLabel } = useInstitutionEmployee()
</script>
