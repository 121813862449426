<template>
  <p class="font-bold text-xl mb-4">
    {{ t('tutorials.extranet.institution_employee.approve.title') }}
  </p>

  <p>
    {{ t('tutorials.extranet.institution_employee.approve.content') }}
  </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
